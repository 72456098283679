<template>
	<div class="page-wrapper">
		<!-- WRAPPER -->
		<div class="list-scroll">
			<div class="list-scroll-header">
				<h3 class="instructions">Select the Repository and select the sale to display the reviews.</h3>
				<form class="review-sales"  @submit.prevent>
					<fieldset>
						<label class="input-label">Repository</label>
						<v-select
							:options="repositories"
							:model="repositorySelected"
							:value="repositorySelected && repositories.find(repo => repo.id === repositorySelected)"
							label="name"
							:searchable="false"
							placeholder="Select a repository"
							class="select-repository"
							@input="onChangeRepositorySelect($event)"
						/>
					</fieldset>
					<fieldset v-if="labelsForSelectedSale.length && search.saleId">
						<label class="input-label">Sales</label>
						<v-select
							ref="selectSale"
							:options="salesByRepository"
							:model="saleSelected"
							label="name"
							:searchable="false"
							placeholder="Select a Sale"
							class="select-sale"
							:disabled="!salesByRepository.length"
							:value="saleSelected && salesByRepository.find(sale => sale.id === saleSelected)"
							@input="onChangeSaleSelect($event)"
						/>
					</fieldset>
				</form>
				<form class="review-sales-sync" @submit.prevent>
					<fieldset>
						<datepicker
						  format="yyyy/MM/DD"
							:value="selectedDate"
							class="date-picker"
							visibility="click"
							:allow-clear="false"
							@input="setSelectedDate($event)"
						/></fieldset>
					<fieldset>
						<ast-button
							id="sync-reviews"
							class="btn btn-success"
							:disabled="!selectedDate"
							@click.native="syncStudiesBtn()"
							>Sync Studies</ast-button
						>
					</fieldset>
				</form>
				<form class="review-search-filter" @submit.prevent>
					<fieldset>
						<ast-input
							label="Search"
							:debounce-milliseconds="250"
							:value="currentHipNumber"
							:placeholder="translate('consignerStudy') + ' number'"
							:only-numbers="true"
							:disabled="!saleSelected"
							@input="currentHipNumber => onInputSearchChange(currentHipNumber)"
						/>
					</fieldset>
					<fieldset class="status">
						<div class="status-container">
							<label class="input-label">Status</label>
							<div class="status-options" :class="saleSelected ? '' : 'no-sale-selected'">
								<ast-radio
									v-for="status in statusList"
									:key="status.value"
									v-model="statusFilter"
									:value="status.value"
								>
									<span class="status-label">{{ status.label }}</span>
									<span class="status-total">{{ saleSelected ? reviewsTotal[status.value] : 0 }}</span>
								</ast-radio>
							</div>
						</div>
						<ast-button
							v-if="currentStatus === 'approved' && tableData.length"
							id="submit-reviews"
							class="btn btn-success"
							@click.native="submitReviewsBtn()"
							>Submit All</ast-button
						>
					</fieldset>
				</form>
			</div>
			<div v-show="tableData.length" class="list-scroll-body">
				<!--Table list-->
				<review-table
					:list-sort="listSort"
					:table-data="tableData"
					:is-loading-data="isLoadingData"
					:update-sort-order="updateSortOrder"
					:handle-row-open="handleRowOpen"
				/>
				<!-- Results are loaded, no results -->
				<section v-show="isLoadingData && !reviews.results.length" class="no-results">
					<em v-if="isLoadingData">
						Loading reviews...
					</em>
					<em v-else>
						No results found
					</em>
				</section>
			</div>
			<div v-show="!tableData.length && salesByRepository.length && saleSelected && !isLoadingData">
				<h3>No reviews for the sale selected or status selected.</h3>
			</div>
			<ast-pagination
				v-show="tableData.length"
				:current-page="sort.currentPage"
				:results-per-page="sort.resultsPerPage"
				:results-length="tableData.length"
				:has-more-results="reviews.hasMoreResults"
				@set-results-per-page="value => updateSort({ resultsPerPage: value })"
				@set-current-page="value => updateSort({ currentPage: value })"
			/>
		</div>
		<simple-loading :is-loading-data="isLoadingData" />
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ReviewTable from './ReviewTable.vue'
import consultations from '@services/consultationService'
import salesService from '@services/salesService'
import AstPagination from '@components/Pagination'
import AstInput from '@components/Input'
import AstRadio from '@components/Radio'
import AstButton from '@components/Button'
import SimpleLoading from '@components/SimpleLoading'
import { statusMap } from './shared'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import Datepicker from '@components/DatePicker.vue'

export default {
	name: 'ConsignerStudyList',
	components: {
		ReviewTable,
		AstPagination,
		AstInput,
		AstRadio,
		AstButton,
		SimpleLoading,
		Datepicker
	},
	data() {
		return {
			currentHipNumber: '',
			currentReviews: [],
			oldStatus: '',
			currentStatus: 'awaitingreview',
			isLoading: false,
			isLoadingResults: false,
			repositories: [],
			repositorySelected: '',
			saleSelected: '',
			salesByRepository: [],
			statusList: [],
			tableData: [],
			timespans: [],
			selectedDate: this.dateFormater(new Date()),
		}
	},
	computed: {
		...mapState({
			sales: state => state.saleSubmission.activeSales,
			clinicCode: state => state.auth.claims.activeClinicCode,
			search: state => state.saleSubmission.search,
			sort: state => state.repositoryReview.sort,
			reviews: state => state.repositoryReview.reviews,
			reviewsTotal: state => state.repositoryReview.reviewsTotal,
			currentParams: state => state.repositoryReview.currentParams,
		}),
		...mapGetters(['isLoadingData']),
		labelsForSelectedSale() {
			if (!this.sales.length || !this.search.saleId) return []
			return this.sales.find(sale => sale.id === this.search.saleId).labels
		},
		statusFilter: {
			get() {
				return this.currentStatus
			},
			set(status) {
				this.oldStatus = this.currentStatus
				this.currentStatus = status
				this.$router.replace({ query: { 'filter-preset': status } })
				this.loadRepositoryData(this.saleSelected, status)
			},
		},
		isChipIdColumn() {
			return this.columns.some(col => col.columnName === 'chipId')
		},
		listSort() {
			return {
				orderBy: this.sort.order.by,
				isAscending: this.sort.order.isAscending,
				isSorted: true,
			}
		},
	},
	watch: {
		async '$route.query'(newQ) {
			const status = newQ['filter-preset']
			if (status && status !== this.currentStatus && this.isValidStatus(status)) {
				this.currentStatus = status
				await this.loadRepositoryData()
			}
		},
	},
	created() {
		this.statusList = statusMap
		this.setIsLoadingDataAction(true)
	},
	async mounted() {
		const consultants = await consultations.getConsultants()
		const { status, consultantId, saleId } = this.currentParams
		this.repositories = await consultants.results.filter(consultant => consultant.type === 'Repository')
		if (status || sessionStorage.getItem('status')) {
			this.currentStatus = status || sessionStorage.getItem('status')
			this.repositorySelected = consultantId || sessionStorage.getItem('consultantId')
			this.saleSelected = saleId || sessionStorage.getItem('saleId')
			this.$router.replace({ query: { 'filter-preset': this.currentStatus } })
			this.salesByRepository = await salesService.getSubmissionSales(this.repositorySelected)
			this.loadRepositoryData()
		} else {
			if (this.$route.query['filter-preset']) {
				this.currentStatus = this.$route.query['filter-preset']
			}
		}
		this.setIsLoadingDataAction(false)
		this.timespans = ['One month', 'Three months', 'Six months']
	},
	methods: {
		...mapActions([
			'getSalesReviewListAction',
			'updateSortAction',
			'setIsLoadingDataAction',
			'clearData',
			'setReviewParamsAction',
			'submitApprovedReviewListAction',
			'syncStudies',
		]),
		async loadRepositoryData(
			saleId = this.saleSelected,
			status = this.currentStatus,
			hipNumber = this.currentHipNumber
		) {
      this.setReviewParamsAction({
        saleId: this.saleSelected,
        status: this.currentStatus,
        consultantId: this.repositorySelected,
      })
			const params = {
				status: this.$route.query['filter-preset'] || status,
				saleId: saleId,
				hipNumber: hipNumber,
				isOrderAscending: this.sort.order.isAscending,
				orderBy: this.sort.order.by,
				page: this.sort.currentPage,
				results: this.sort.resultsPerPage,
			}
			if (this.repositorySelected) {
				await this.getSalesReviewListAction({ consultantId: this.repositorySelected, params })
				this.sortedResults()
			}
		},
		async submitReviewsBtn() {
			if (await showConfirm('Are you sure you want to send all approved hips to the selected repository sale?')) {
				let approvedReviews = this.tableData.filter(review => review.status.toLowerCase() === 'approved')
				approvedReviews = approvedReviews.map(review => review.id)
				await this.submitApprovedReviewListAction({
					approvedReviews,
				})
				this.loadRepositoryData()
			}
		},
		async syncStudiesBtn() {
			await this.syncStudies(this.selectedDate)
			await this.loadRepositoryData()
		},
		isValidStatus(status) {
			const isValid = this.statusList.filter(item => item.value === status)
			return !!isValid.length
		},
		sortedResults() {
			const prop = this.sort.order.by
			let results = [...this.reviews.results].sort((a, b) => {
				if (a[prop] < b[prop]) return -1
				if (a[prop] > b[prop]) return 1
				return 0
			})
			if (!this.sort.order.isAscending) results.reverse()
			this.tableData = results
		},
		handleRowOpen(row) {
			this.setReviewParamsAction({
				saleId: this.saleSelected,
				status: this.currentStatus,
				consultantId: this.repositorySelected,
			})
			this.$router.push({
				name: 'repository-review-details',
				params: {
					id: row.id,
				},
			})
		},
		async onChangeRepositorySelect(repository) {
			if (repository) {
				this.repositorySelected = repository.id
				this.salesByRepository = await salesService.getSubmissionSales(repository.id)
			} else {
				this.salesByRepository = []
				await this.clearRepositoryData()
			}
		},
		async onChangeSaleSelect(sale) {
			if (sale) {
				this.saleSelected = sale.id
				await this.loadRepositoryData(sale.id)
			} else {
				this.saleSelected = ''
				await this.clearSalesData()
			}
		},
		onInputSearchChange(value) {
			this.currentHipNumber = value
			setTimeout(async () => {
				await this.loadRepositoryData()
			}, 300)
		},
		async clearSalesData() {
			await this.clearData()
			this.currentHipNumber = ''
			this.currentReviews = []
			this.saleSelected = ''
			this.tableData = []
		},
		async clearRepositoryData() {
			await this.clearData()
			this.currentHipNumber = ''
			this.currentReviews = []
			this.saleSelected = ''
			this.tableData = []
			this.repositorySelected = ''
		},
		async updateSortOrder(evt) {
			const changes = {
				order: {
					by: evt.name,
					isAscending: evt.isAscending,
				},
			}
			await this.updateSortAction(changes)
			await this.loadRepositoryData()
		},
		async updateSort(payload) {
			await this.updateSortAction(payload)
			await this.loadRepositoryData()
		},
		setSelectedDate(date) {
			this.selectedDate = this.dateFormater(date, '-')
		},
		dateFormater(date) {
		var day = date.getUTCDate()
		var month = date.getUTCMonth() + 1
		const year = date.getUTCFullYear()
		const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const milliseconds = date.getUTCMilliseconds();

		// show date and month in two digits
		if (day < 10) {
			day = '0' + day;
		}
		if (month < 10) {
			month = '0' + month;
		}

		// now we have day, month and year
		// use the separator to join them
		return `${year}-${month}-${day}
            ${hours}:${minutes}:${seconds}.${milliseconds}`;
	},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';
.instructions {
	margin-bottom: 20px;
}
.list-scroll {
	.list-scroll-header {
		display: flex;
		flex-flow: column wrap;
		margin-bottom: 20px;
	}
}
fieldset {
	margin-right: 20px;
	border: none;
	.v-select {
		margin-bottom: 20px;
	}

	&.status {
		display: flex;
	}
}

#submit-reviews {
	align-self: flex-end;
	max-height: 40px;
}

.review-sales {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 400px;
	margin-bottom: 20px;
}
.review-sales-sync {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 200px;
	margin-left: 550px;
	fieldset {
		> .input-wrapper {
			max-width: 400px;
		}
	}
}
.status-options {
	display: flex;
	align-items: center;
	.status-label {
		margin-right: 10px;
	}
	.status-total {
		font-weight: bold;
	}

	&.no-sale-selected {
		cursor: not-allowed;
		> .radio {
			pointer-events: none;
		}
	}
}
.review-search-filter {
	display: flex;
	@media screen and (max-width: 800px) {
		flex-direction: column;
		fieldset:first-child {
			margin-bottom: 20px;
		}
	}
	fieldset {
		> .input-wrapper {
			max-width: 200px;
		}
		.radio {
			display: flex;
			align-items: center;
			height: 36px;
		}
	}
}
.repository-logo {
	float: right;
	max-width: 300px;
	object-fit: contain;
	object-position: 0 0;
	height: 100px;
	max-height: 100px;
	margin-bottom: 0.5em;
}
.study-card {
	&:nth-child(odd) {
		background: var(--table-row-odd-bg);
	}
	li span {
		padding-left: 8px;
		font-weight: 300;
	}
}
span.attachment {
	padding-right: 0.5rem;
	white-space: nowrap;
}
.attachment abbr {
	font-size: 0.75em;
	font-weight: 500;
}
.select-repository {
	input[type='search'] {
		margin-top: 2px;
	}
}
.date-picker h3 {
		white-space: nowrap;
		font-size: 1.1em;
		cursor: pointer;
		padding: 8px 16px;
	}
</style>
